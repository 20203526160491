import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import fetchWrapper from '@/utils/fetchWrapper';
import * as securityUtils from '@/utils/securityUtils';
import sanitizationMiddleware from '@/utils/sanitizationMiddleware';

// Create a secure fetch wrapper that adds CSRF token to requests
if (process.env.NODE_ENV === "development") {
  // Store original fetch before overriding
  window.originalFetch = window.fetch;
  window.fetch = fetchWrapper;
}

const app = createApp(App);

// Make security utilities available globally
app.config.globalProperties.$security = securityUtils;

// Install sanitization middleware
app.use(sanitizationMiddleware);

// Add global XSS protection directive
app.directive('safe-html', {
  mounted(el, binding) {
    el.innerHTML = binding.value ? binding.value : '';
  },
  updated(el, binding) {
    el.innerHTML = binding.value ? binding.value : '';
  }
});

router.afterEach(() => {
  // Collapse the navbar
  const navbarCollapse = document.querySelector('.navbar-collapse');
  if (navbarCollapse && navbarCollapse.classList.contains('show')) {
    navbarCollapse.classList.remove('show');
  }
});

app.use(router);
app.mount('#app');
