/**
 * Client-side sanitization middleware
 * Provides utilities to sanitize user input and prevent XSS attacks
 */

import { sanitizeHtml } from './securityUtils';

/**
 * Sanitizes all properties of an object recursively
 * @param {Object} obj - The object to sanitize
 * @returns {Object} - The sanitized object
 */
export const sanitizeObject = (obj) => {
  if (!obj || typeof obj !== 'object') {
    return obj;
  }

  // Handle arrays
  if (Array.isArray(obj)) {
    return obj.map(item => sanitizeObject(item));
  }

  // Handle regular objects
  const sanitized = {};
  for (const [key, value] of Object.entries(obj)) {
    if (typeof value === 'string') {
      sanitized[key] = sanitizeHtml(value);
    } else if (typeof value === 'object' && value !== null) {
      sanitized[key] = sanitizeObject(value);
    } else {
      sanitized[key] = value;
    }
  }

  return sanitized;
};

/**
 * Vue directive for sanitizing HTML content
 * Usage: v-sanitize
 */
export const sanitizeDirective = {
  mounted(el, binding) {
    if (binding.value === false) return; // Skip if explicitly disabled
    el.textContent = sanitizeHtml(el.innerHTML);
  },
  updated(el, binding) {
    if (binding.value === false) return; // Skip if explicitly disabled
    el.textContent = sanitizeHtml(el.innerHTML);
  }
};

/**
 * Sanitizes form inputs before submission
 * @param {Event} event - The form submission event
 */
export const sanitizeFormSubmit = (event) => {
  const form = event.target;
  const formData = new FormData(form);
  
  // Sanitize each form field
  for (const [key, value] of formData.entries()) {
    if (typeof value === 'string') {
      formData.set(key, sanitizeHtml(value));
    }
  }
  
  return formData;
};

/**
 * Install the sanitization middleware into a Vue application
 * @param {Vue} app - The Vue application instance
 */
export const installSanitization = (app) => {
  // Register the sanitize directive
  app.directive('sanitize', sanitizeDirective);
  
  // Add a global mixin for form submissions
  app.mixin({
    methods: {
      $sanitizeInput(value) {
        return typeof value === 'string' ? sanitizeHtml(value) : value;
      },
      $sanitizeObject(obj) {
        return sanitizeObject(obj);
      }
    }
  });
};

export default {
  install: installSanitization
};
