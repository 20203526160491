/**
 * Security utilities for client-side application
 */

// CSRF token management
const getCsrfToken = () => {
  return localStorage.getItem('csrfToken');
};

const setCsrfToken = (token) => {
  localStorage.setItem('csrfToken', token);
};

// Secure token handling
const getAuthToken = () => {
  return localStorage.getItem('token');
};

const setAuthToken = (token) => {
  localStorage.setItem('token', token);
};

const clearAuthToken = () => {
  localStorage.removeItem('token');
};

// XSS protection - sanitize content
const sanitizeHtml = (html) => {
  if (!html) return '';
  
  // Basic HTML sanitization
  return html
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#039;');
};

// Safe content rendering
const createSafeHtml = (html) => {
  if (!html) return { __html: '' };
  return { __html: html };
};

// Input validation
const validateEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const validatePassword = (password) => {
  // At least 8 characters, 1 uppercase, 1 lowercase, 1 number, 1 special character
  const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[a-zA-Z\d!@#$%^&*]{8,}$/;
  return re.test(password);
};

export {
  getCsrfToken,
  setCsrfToken,
  getAuthToken,
  setAuthToken,
  clearAuthToken,
  sanitizeHtml,
  createSafeHtml,
  validateEmail,
  validatePassword
};
